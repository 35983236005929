<template>
  <v-dialog v-model="uploadDialog" persistent max-width="700">
    <v-card>
      <v-card-title>Upload Subscribers</v-card-title>
      <v-card-text>
        <v-file-input
          truncate-length="15"
          clearable
          show-size
          v-model="uploads"
          @change="fileupload($event)"
        ></v-file-input>
      </v-card-text>
      <v-card-actions>
        <span class="error_message">{{ responseMessage }}</span>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn small text color="red" v-on="on" @click="closeDialog">
              NO
            </v-btn>
          </template>
          <span>cancel</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn small text color="blue" v-on="on" @click="submit">
              yes
            </v-btn>
          </template>
          <span>Submit</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
//   import Restful from "@/services/RestFul";
//   import moment from "moment";
//   import Utils from "./../../../../mixins/utils";
export default {
  props: {
    uploadDialog: Boolean,
    subType: { type: Number, default: 0 },
    myRole: { type: Number, default: 0 },
  },
  // mixins: [Utils],
  data() {
    return {
      responseMessage: null,
      uploads: [],
      file: null,
      myfileUpload: null,
      myfile: null,
    };
  },
  created() {},
  methods: {
    fileupload(event) {
      this.myfile = new FormData();
      this.myfile.append("file", event[0]);
      this.file = event;

      const reader = new FileReader();
      reader.readAsText(this.file);
      reader.onload = (e) => {
        this.filfileUploadUpload = e.target.result;
        this.myfileUpload = e.target.result;
      };
    },
    csvToJson(csv) {
      var lines = csv.split("\n");
      var result = [];
      var headers = lines[0].split(",");
      for (var i = 1; i < lines.length; i++) {
        var obj = {};
        var currentline = lines[i].split(",");
        for (var j = 0; j < headers.length; j++) {
          obj[headers[j]] = currentline[j];
        }
        result.push(obj);
      }
      console.log(":", result);
      //   return JSON.stringify(result, null, " ").replace("\r", "");
      return result;
    },
    closeDialog() {
      let payload = { id: this.myId, state: false };
      this.$emit("closeUpload", payload);
    },
    submit() {
      let subscriber_types_id = this.subType;
      let jsonData = this.csvToJson(this.filfileUploadUpload);
      let data = { subscriber_types_id, jsonData };
      console.log("data", data);
      //
      let payload = { id: this.myId, state: false, data: data };
      this.$emit("submitUpload", payload);
    },
  },
};
</script>
