<template>
  <v-dialog v-model="newDialog" persistent max-width="700">
    <v-card>
      <v-card-title>New Patient</v-card-title>
      <v-card-text>
        <v-row no-gutters>
          <v-col cols="12" sm="6">
            <v-select
              dense
              label="gender"
              v-model="view.gender"
              :items="gendersList"
              item-text="name"
              item-value="id"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6">
            <v-select
              dense
              label="salutations"
              v-model="view.salutationid"
              :items="salutationList"
              item-text="name"
              item-value="id"
            ></v-select>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="12" sm="6">
            <v-text-field
              label="first name"
              dense
              v-model="view.firstName"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              label="last name"
              dense
              v-model="view.lastName"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="12" sm="6">
            <v-text-field
              label="mobile Phone"
              dense
              counter
              maxLength="10"
              v-model="view.mobilePhone"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              label="email"
              dense
              v-model="view.email"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="12" sm="6">
            <v-select
              dense
              label="country"
              v-model="view.countryId"
              :items="countrys"
              item-text="name"
              item-value="id"
              return-object
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6">
            <v-select
              dense
              :disabled="regAble"
              label="regionsId"
              v-model="view.regionsId"
              :items="regions"
              item-text="name"
              item-value="id"
              return-object
            ></v-select>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="12" sm="6">
            <v-select
              dense
              :disabled="distAble"
              label="district"
              v-model="view.districtsId"
              :items="districts"
              item-text="name"
              item-value="id"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              :disabled="cityAble"
              dense
              label="city"
              v-model="view.city"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="12" sm="12">
            <v-text-field
              label="physical Address"
              dense
              v-model="view.physicalAddress"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <span class="error_message">{{ responseMessage }}</span>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn small text color="red" v-on="on" @click="closeDialog">
              NO
            </v-btn>
          </template>
          <span>cancel</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              small
              text
              color="blue"
              v-on="on"
              @click="submit"
              :disabled="$v.view.$invalid"
            >
              yes
            </v-btn>
          </template>
          <span>Submit</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import Restful from "@/services/RestFul";
import {
  required,
  email,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],
  props: {
    newDialog: Boolean,
    subType: { type: Number, default: 0 },
    myRole: { type: Number, default: 0 },
  },
  data() {
    return {
      regAble: true,
      distAble: true,
      cityAble: true,
      spesAble: true,
      loading: false,
      responseMessage: null,
      salutationList: [],
      countrys: [],
      regions: [],
      districts: [],
      gendersList: [],
      specialtyList: [],
      specialtyDetailList: [],
      view: {
        gender: null,
        salutationid: null,
        firstName: null,
        lastName: null,
        dependency: false,
        subscriberTypesId: null,
        subscribersid: null,
        email: null,
        mobilePhone: null,
        physicalAddress: null,
        countryId: null,
        regionsId: null,
        districtsId: null,
        city: null,
      },
    };
  },
  validations: {
    view: {
      firstName: {
        required,
      },
      lastName: {
        required,
      },
      gender: {
        required,
      },
      physicalAddress: {
        required,
      },
      mobilePhone: {
        required,
        maxLength: maxLength(10),
        minLength: minLength(10),
      },
      email: {
        required,
        email,
      },
      countryId: {
        required,
      },
      regionsId: {
        required,
      },
      districtsId: {
        required,
      },
      city: {
        required,
      },
    },
  },
  watch: {
    "view.countryId"(id) {
      console.log(id.id);
      this.regionlist(id.id);
      this.regAble = false;
    },
    "view.regionsId"(id) {
      console.log(id.id);
      this.districtlist(id.id);
      this.distAble = false;
    },
    "view.districtsId"(id) {
      if (id > 0) {
        console.log(id.id);
        this.cityAble = false;
      }
    },
  },
  created() {
    this.salutations();
    this.country();
    this.genders();
  },
  methods: {
    genders() {
      Restful.general.genders
        .list()
        .then((response) => {
          console.log(response);
          this.gendersList = response.data;
        })
        .catch((error) => {
          console.log(error.response);
          self.loading = false;
        });
    },
    salutations() {
      Restful.general.salutations
        .list()
        .then((response) => {
          console.log(response);
          this.salutationList = response.data;
        })
        .catch((error) => {
          console.log(error.response);
          self.loading = false;
        });
    },
    country() {
      Restful.general.country
        .list()
        .then((response) => {
          console.log(response);
          this.countrys = response.data;
        })
        .catch((error) => {
          console.log(error.response);
          self.loading = false;
        });
    },
    regionlist(country) {
      Restful.general.regions
        .byCountry(country)
        .then((response) => {
          console.log(response);
          this.regions = response.data;
        })
        .catch((error) => {
          console.log(error.response);
          self.loading = false;
        });
    },
    districtlist(reg) {
      Restful.general.districts
        .findbyRegions(reg)
        .then((response) => {
          console.log(response);
          this.districts = response.data;
        })
        .catch((error) => {
          console.log(error.response);
          self.loading = false;
        });
    },
    closeDialog() {
      let payload = { id: this.myId, state: false };
      this.$emit("closeNew", payload);
    },
    submit() {
      // console.log(this.view);
      let city = this.view.city;
      let countryid = this.view.countryId.id;
      let dependency = this.view.dependency;
      let districtsid = this.view.districtsId;
      let email = this.view.email;
      let first_name = this.view.firstName;
      let gendersid = this.view.gender;
      let last_name = this.view.lastName;
      let mobile_phone = this.view.mobilePhone;
      let physical_address = this.view.physicalAddress;
      let regionsid = this.view.regionsId.id;
      let relatedTo = "";
      let relation_typesid = "";
      let salutationid = this.view.salutationid;
      let subscriber_types_id = this.subType;

      let data = {
        city,
        countryid,
        dependency,
        districtsid,
        email,
        first_name,
        gendersid,
        last_name,
        mobile_phone,
        physical_address,
        regionsid,
        relatedTo,
        relation_typesid,
        salutationid,
        subscriber_types_id,
      };
      console.log("data:", data);

      let payload = { state: false, data: data };
      console.log("payload:", payload);
      this.$emit("submitNew", payload);
    },
  },
};
</script>
